'use strict';

angular.module('enervexSalesappApp').factory('Invitation', function ($resource) {
	return $resource('/api/accounts/:accountId/jobs/:jobId/invitations/:id/:accept/:reject', {
		id: '@_id'
	},
	{
		accept: {
			method: 'POST',
			params: {
				id: '@id',
				accept: 'accept'
			}
		},
		reject: {
			method: 'POST',
			params: {
				id: '@id',
				reject: 'reject'
			}
		}
	});
});
